define("ember-aria-tabs/components/aria-tab-panel", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "ember-cached-decorator-polyfill", "@ember/array", "@ember/object", "@ember/object/internals"], function (_exports, _component, _templateFactory, _component2, _tracking, _emberCachedDecoratorPolyfill, _array, _object, _internals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    aria-labelledby={{@tabId}}
    class={{this.classNames}}
    id={{this.elementId}}
    role="tabpanel"
    {{did-insert this.didInsertNode}}
    {{will-destroy this.willDestroyNode}}
    ...attributes
  >
    {{~#if this.shouldYield~}}
      {{yield (hash selected=this.selected)}}
    {{/if}}
  </div>
  */
  {
    "id": "5cWqRtAd",
    "block": "[[[11,0],[16,\"aria-labelledby\",[30,1]],[16,0,[30,0,[\"classNames\"]]],[16,1,[30,0,[\"elementId\"]]],[24,\"role\",\"tabpanel\"],[17,2],[4,[38,0],[[30,0,[\"didInsertNode\"]]],null],[4,[38,1],[[30,0,[\"willDestroyNode\"]]],null],[12],[41,[30,0,[\"shouldYield\"]],[[[18,3,[[28,[37,4],null,[[\"selected\"],[[30,0,[\"selected\"]]]]]]],[1,\"\\n\"]],[]],null],[13]],[\"@tabId\",\"&attrs\",\"&default\"],false,[\"did-insert\",\"will-destroy\",\"if\",\"yield\",\"hash\"]]",
    "moduleName": "ember-aria-tabs/components/aria-tab-panel.hbs",
    "isStrictMode": false
  });

  const DEFAULT_CLASS = 'ember-tabs__tab-panel';
  /**
   * A tab panel component rendered as `<div />`.
   *
   * If you specify additional props on the `<AriaTabPanel />` component they will be forwarded to the rendered `<div />`.
   *
   * Default CSS class: `ember-tabs__tab-panel`
   *
   * @class AriaTabPanel
   * @public
   */

  let AriaTabPanelComponent = (_class = class AriaTabPanelComponent extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "elementId", _descriptor, this);
    }

    /**
     * Defaults to `"ember-tabs__tab-panel--selected"`.
     *
     * Provide a custom class name for the active tab panel.
     *
     * > This option can also be set for all `<AriaTabPanel />` components with the prop `selectedTabPanelClassName` on `<AriaTabs />`.
     *
     * @argument selectedClassName
     * @type String
     * @default "ember-tabs__tab-panel--selected"
     */
    get selectedClassName() {
      return this.args.selectedClassName ?? this.args.selectedTabPanelClassName ?? `${DEFAULT_CLASS}--selected`;
    }
    /**
     * Defaults to `false`.
     *
     * By default the tab content will only be rendered when the tab is active. If set to `true` the tab will also be rendered if inactive.
     *
     * > This can also be enabled for all `<AriaTabPanel />` components with the prop `forceRenderTabPanel` on `<AriaTabs />`.
     *
     * @argument forceRender
     * @type Boolean
     * @default false
     */


    get shouldYield() {
      return this.selected || this.args.forceRender;
    }

    get nodeIndex() {
      return (0, _array.A)(this.args.panelIds).indexOf(this.elementId);
    }

    get tabId() {
      return (0, _array.A)(this.args.tabIds)[this.nodeIndex];
    }

    get selected() {
      return this.nodeIndex === this.args.selectedIndex;
    }

    get classNames() {
      let classNames = [DEFAULT_CLASS];

      if (this.selected) {
        classNames.push(this.selectedClassName);
      }

      return classNames.join(' ');
    }

    didInsertNode(element) {
      this.elementId = element.id;

      if (typeof this.args.didInsertNode === 'function') {
        this.args.didInsertNode(this.elementId, ...arguments);
      }
    }

    willDestroyNode() {
      if (typeof this.args.willDestroyNode === 'function') {
        this.args.willDestroyNode(this.elementId, ...arguments);
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "selectedClassName", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "selectedClassName"), _class.prototype), _descriptor = _applyDecoratedDescriptor(_class.prototype, "elementId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _internals.guidFor)(this);
    }
  }), _applyDecoratedDescriptor(_class.prototype, "nodeIndex", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "nodeIndex"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "tabId", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "tabId"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selected", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "selected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "classNames", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "classNames"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "didInsertNode", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didInsertNode"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "willDestroyNode", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "willDestroyNode"), _class.prototype)), _class);
  _exports.default = AriaTabPanelComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AriaTabPanelComponent);
});