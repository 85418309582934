define("ember-tracked-nested/-private/decorator", ["exports", "@glimmer/tracking", "ember-tracked-nested"], function (_exports, _tracking, _emberTrackedNested) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.trackedNested = void 0;

  // @ts-ignore
  const trackedNested = function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    const [target, key, descriptor] = args;
    return descriptorForField(target, key, descriptor);
  };

  _exports.trackedNested = trackedNested;

  function getProxiableValue(value, context, key) {
    return value === undefined || value === null ? value : (0, _emberTrackedNested.nested)(value, context, key).data;
  }

  function descriptorForField(_target, key, desc) {
    if (desc) {
      const value = desc.initializer;

      desc.initializer = function () {
        const initialValue = value?.call(this);
        return getProxiableValue(initialValue, this, key);
      };
    } // @ts-ignore


    const {
      enumerable,
      configurable,
      get,
      set
    } = (0, _tracking.tracked)(_target, key, desc);
    return {
      enumerable,
      configurable,

      /* eslint-disable */
      // @ts-ignore
      get() {
        return get.call(this);
      },

      // @ts-ignore
      set(newValue) {
        const proxiedValue = newValue?.__isObservedProxy ? newValue : getProxiableValue(newValue, this, key);
        set.call(this, proxiedValue);
      }
      /* eslint-enable */


    };
  }
});