define("ember-svg-jar/inlined/setpoint", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M6.818 10.717a2.172 2.172 0 110 4.344 2.172 2.172 0 010-4.344zM15.38 7.086a2.172 2.172 0 110 4.345 2.172 2.172 0 010-4.345zM12.36 15.063a2.172 2.172 0 110 4.344 2.172 2.172 0 010-4.345zM2 17.53l3.1-3.311M8.713 13.973l1.988 1.854M13.318 15.271l1.434-3.937\" stroke=\"currentColor\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M2 6.5v15.553h15.553\" stroke=\"currentColor\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "24",
      "height": "25",
      "viewBox": "0 0 24 25",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});