define("ember-svg-jar/inlined/history 2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>icon/history 2</title><g fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke=\"#F2F4F6\" stroke-width=\"1.5\"><path d=\"M13.267 20c4.547 0 8.233-3.582 8.233-8s-3.686-8-8.233-8C8.72 4 5.033 7.582 5.033 12v.615\"/><path d=\"M2.5 10.151l2.533 2.462 2.534-2.462M12.633 7.074v5.539h4.434\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});