define("ember-svg-jar/inlined/roadblock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>_army / icon / laneStatus / roadblock</title><g transform=\"translate(4 4)\" stroke=\"#333\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><rect y=\"3.2\" width=\"16\" height=\"7.467\" rx=\".711\"/><path d=\"M2.133 10.667V16M13.867 10.667V16\"/><circle cx=\"3.733\" cy=\"1.067\" r=\"1.067\"/><circle cx=\"12.267\" cy=\"1.067\" r=\"1.067\"/><path d=\"M5.333 3.2L0 8.533M9.6 3.2l-7.467 7.467M13.867 3.2L6.4 10.667M16 5.333l-5.333 5.334\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});