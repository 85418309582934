define("ember-svg-jar/inlined/phone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>icon/phone</title><g stroke=\"#000\" stroke-width=\"1.71\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M19.5 19.2c0 1.587-1.3 2.8-3 2.8H7c-1.7 0-3-1.213-3-2.8V3.8C4 2.213 5.3 1 7 1h9.5c1.7 0 3 1.213 3 2.8v15.4zM4 17.8h15.5\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});