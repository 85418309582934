define("ember-svg-jar/inlined/comment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>icon/comment</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"currentColor\" d=\"M19 15.182h-7.636L7.545 19v-3.818H5V5h14z\"/><path stroke=\"#FFF\" stroke-width=\"1.273\" stroke-linejoin=\"round\" d=\"M7.545 8.818h8.91M7.545 11.364h5.728\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});