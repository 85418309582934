define("ember-elsewhere/services/ember-elsewhere", ["exports", "@ember/service", "@ember/runloop", "@ember/object", "@ember/array", "@glimmer/tracking"], function (_exports, _service, _runloop, _object, _array, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let _class = (_class2 = class _class2 extends _service.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "actives", _descriptor, this);

      _defineProperty(this, "_alive", {});

      _defineProperty(this, "_counter", 1);
    }

    show(sourceId, name, component, outsideParams) {
      let order = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 0;

      // if current component has specific order that is greater than current internal count
      // update internal count so any subsequent component that does not provide order will
      // be after.
      if (this._counter < order) {
        this._counter = order + 1;
      }

      this._alive[sourceId] = {
        target: name || 'default',
        component,
        order: order || this._counter++,
        outsideParams
      };

      this._schedule();
    }

    clear(sourceId) {
      delete this._alive[sourceId];

      this._schedule();
    }

    _schedule() {
      (0, _runloop.scheduleOnce)('afterRender', this, this._process);
    }

    _process() {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }

      let newActives = {};
      let alive = this._alive;
      Object.keys(alive).forEach(sourceId => {
        let {
          target,
          component,
          order,
          outsideParams
        } = alive[sourceId];
        newActives[target] = newActives[target] || (0, _array.A)();
        let newActive = component ? {
          component,
          order,
          outsideParams
        } : null;
        newActives[target].push(newActive);
      });
      Object.keys(newActives).forEach(target => {
        newActives[target] = (0, _array.A)(newActives[target].sortBy('order'));
      });
      this.actives = _object.default.create(newActives);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "actives", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return _object.default.create();
    }
  })), _class2);

  _exports.default = _class;
});