define("ember-svg-jar/inlined/schedule", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\" stroke=\"currentColor\" stroke-linejoin=\"round\" stroke-width=\"1.5\"><path d=\"M17.364 6.545V3.273H1v13.091h6.136M4.273 3.273V0m9.818 3.273V0M1 7.364h8.182\"/><path d=\"M14.09 18.818a4.91 4.91 0 100-9.818 4.91 4.91 0 000 9.818z\"/><path d=\"M16.545 13.909h-2.454v-3.273\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20"
    }
  };
  _exports.default = _default;
});