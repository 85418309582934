define("ember-aria-tabs/components/aria-tab", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "ember-cached-decorator-polyfill", "@ember/array", "@ember/object", "@ember/object/internals", "@ember/runloop"], function (_exports, _component, _templateFactory, _component2, _tracking, _emberCachedDecoratorPolyfill, _array, _object, _internals, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! template-lint-disable no-positive-tabindex}}
  <li
    aria-controls={{this.panelId}}
    aria-disabled={{if @disabled "true" "false"}}
    aria-selected={{if this.selected "true" "false"}}
    class={{this.classNames}}
    disabled={{@disabled}}
    id={{this.elementId}}
    role="tab"
    tabindex={{this.tabIndex}}
    {{did-insert this.didInsertNode}}
    {{will-destroy this.willDestroyNode}}
    {{did-insert this.checkFocus}}
    {{did-update this.checkFocus}}
    {{on "click" this.onClick}}
    {{on "keyup" this.onKeyUp}}
    ...attributes
  >
    {{yield (hash selected=this.selected)}}
  </li>
  */
  {
    "id": "BEgnYmPD",
    "block": "[[[11,\"li\"],[16,\"aria-controls\",[30,0,[\"panelId\"]]],[16,\"aria-disabled\",[52,[30,1],\"true\",\"false\"]],[16,\"aria-selected\",[52,[30,0,[\"selected\"]],\"true\",\"false\"]],[16,0,[30,0,[\"classNames\"]]],[16,\"disabled\",[30,1]],[16,1,[30,0,[\"elementId\"]]],[24,\"role\",\"tab\"],[16,\"tabindex\",[30,0,[\"tabIndex\"]]],[17,2],[4,[38,1],[[30,0,[\"didInsertNode\"]]],null],[4,[38,2],[[30,0,[\"willDestroyNode\"]]],null],[4,[38,1],[[30,0,[\"checkFocus\"]]],null],[4,[38,3],[[30,0,[\"checkFocus\"]]],null],[4,[38,4],[\"click\",[30,0,[\"onClick\"]]],null],[4,[38,4],[\"keyup\",[30,0,[\"onKeyUp\"]]],null],[12],[1,\"\\n  \"],[18,3,[[28,[37,6],null,[[\"selected\"],[[30,0,[\"selected\"]]]]]]],[1,\"\\n\"],[13]],[\"@disabled\",\"&attrs\",\"&default\"],false,[\"if\",\"did-insert\",\"will-destroy\",\"did-update\",\"on\",\"yield\",\"hash\"]]",
    "moduleName": "ember-aria-tabs/components/aria-tab.hbs",
    "isStrictMode": false
  });

  const DEFAULT_CLASS = 'ember-tabs__tab';
  /**
   * A tab component rendered as `<li />`.
   *
   * If you specify additional props on the `<AriaTab />` component they will be forwarded to the rendered `<li />`.
   *
   * Default CSS class: `ember-tabs__tab`
   *
   * @class AriaTab
   * @public
   */

  let AriaTabComponent = (_class = class AriaTabComponent extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "elementId", _descriptor, this);
    }

    /**
     * Defaults to `false`.
     *
     * Disable this tab which will make it not do anything when clicked. Also a disabled class name will be added (see `disabledClassName`)
     *
     * @argument disabled
     * @type Boolean
     * @default false
     */

    /**
     * Defaults to `"ember-tabs__tab--disabled"`.
     *
     * Provide a custom class name for disabled tabs.
     *
     * > This option can also be set for all `<AriaTab />` components with the prop `disabledTabClassName` on `<AriaTabs />`.
     *
     * @argument disabledClassName
     * @type String
     * @default "ember-tabs__tab--disabled"
     */
    get disabledClassName() {
      return this.args.disabledClassName ?? this.args.disabledTabClassName ?? `${DEFAULT_CLASS}--disabled`;
    }
    /**
     * Defaults to `"ember-tabs__tab--selected"`.
     *
     * > This option can also be set for all `<AriaTab />` components with the prop `disabledTabClassName` on `<AriaTabs />`.
     *
     * @argument selectedClassName
     * @type String
     * @default "ember-tabs__tab--selected"
     */


    get selectedClassName() {
      return this.args.selectedClassName ?? this.args.selectedTabClassName ?? `${DEFAULT_CLASS}--selected`;
    }
    /**
     * Default to `"0"` if selected, `null` otherwise.
     *
     * Overrides the tabIndex to enabled tabbing between tabs.
     *
     * @argument tabIndex
     * @type String
     * @default "0"|null
     **/


    get classNames() {
      let classNames = [DEFAULT_CLASS];

      if (this.selected) {
        classNames.push(this.selectedClassName);
      }

      if (this.args.disabled) {
        classNames.push(this.disabledClassName);
      }

      return classNames.join(' ');
    }

    get nodeIndex() {
      return (0, _array.A)(this.args.tabIds).indexOf(this.elementId);
    }

    get panelId() {
      return (0, _array.A)(this.args.panelIds)[this.nodeIndex];
    }

    get selected() {
      return this.nodeIndex === this.args.selectedIndex;
    }

    get tabIndex() {
      return this.args.tabIndex ?? (this.selected ? '0' : undefined);
    }

    checkFocus() {
      if (this.selected && this.args.focus) {
        // We need to wait the selected rendering state
        (0, _runloop.next)(() => {
          this.element.focus();
        });
      }
    }

    didInsertNode(element) {
      this.elementId = element.id;

      if (typeof this.args.didInsertNode === 'function') {
        this.args.didInsertNode(this.elementId, ...arguments);
      }
    }

    willDestroyNode() {
      if (typeof this.args.willDestroyNode === 'function') {
        this.args.willDestroyNode(this.elementId, ...arguments);
      }
    }

    onClick() {
      if (typeof this.args.onClick === 'function') {
        this.args.onClick(this.nodeIndex, ...arguments);
      }
    }

    onKeyUp() {
      if (typeof this.args.onKeyUp === 'function') {
        this.args.onKeyUp(this.nodeIndex, ...arguments);
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "disabledClassName", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "disabledClassName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectedClassName", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "selectedClassName"), _class.prototype), _descriptor = _applyDecoratedDescriptor(_class.prototype, "elementId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _internals.guidFor)(this);
    }
  }), _applyDecoratedDescriptor(_class.prototype, "classNames", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "classNames"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "nodeIndex", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "nodeIndex"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "panelId", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "panelId"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selected", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "selected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "checkFocus", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "checkFocus"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "didInsertNode", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didInsertNode"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "willDestroyNode", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "willDestroyNode"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onKeyUp", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onKeyUp"), _class.prototype)), _class);
  _exports.default = AriaTabComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AriaTabComponent);
});