define("ember-svg-jar/inlined/snow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>_army / icon / weather / snow</title><g stroke=\"currentColor\" stroke-width=\"1.5\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M7.251 16.876c.207 0 .375.167.375.375M6.876 17.25c0-.207.168-.374.375-.374M7.251 17.626a.375.375 0 01-.375-.375M7.626 17.25a.375.375 0 01-.375.376M11.751 16.876c.207 0 .375.167.375.375M11.376 17.25c0-.207.168-.374.375-.374M11.751 17.626a.375.375 0 01-.375-.375M12.126 17.25a.375.375 0 01-.375.376M16.251 16.876c.207 0 .375.167.375.375M15.876 17.25c0-.207.168-.374.375-.374M16.251 17.626a.375.375 0 01-.375-.375M16.626 17.25a.375.375 0 01-.375.376M5.751 21.376c.207 0 .375.167.375.375M5.376 21.75c0-.207.168-.374.375-.374M5.751 22.126a.375.375 0 01-.375-.375M6.126 21.75a.375.375 0 01-.375.376M10.251 21.376c.207 0 .375.167.375.375M9.876 21.75c0-.207.168-.374.375-.374M10.251 22.126a.375.375 0 01-.375-.375M10.626 21.75a.375.375 0 01-.375.376M14.751 21.376c.207 0 .375.167.375.375M14.376 21.75c0-.207.168-.374.375-.374M14.751 22.126a.375.375 0 01-.375-.375M15.126 21.75a.375.375 0 01-.375.376M8.751 7.5a5.993 5.993 0 000 0c.002-.376.04-.752.113-1.121\"/><path d=\"M14.751 13.5A6 6 0 108.864 6.38 3.745 3.745 0 107.251 13.5h7.5z\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});