define("ember-svg-jar/inlined/report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>_army / icon/report</title><g stroke=\"currentColor\" stroke-width=\"1.5\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M7.5 10.5h9M7.5 14.25h9M7.5 18h9M15.75 4.5h3.75A1.5 1.5 0 0121 6v15.75a1.5 1.5 0 01-1.5 1.5h-15a1.5 1.5 0 01-1.5-1.5V6a1.5 1.5 0 011.5-1.5h3.75a3.75 3.75 0 017.5 0z\"/><path d=\"M12 3.75a.375.375 0 110 .75.375.375 0 010-.75\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});