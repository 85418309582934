define("ember-svg-jar/inlined/meter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path stroke=\"currentColor\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M12 4v2.909\"/><path d=\"M12 20a8 8 0 100-16 8 8 0 000 16z\" stroke=\"currentColor\" stroke-width=\"1.5\"/><path stroke=\"currentColor\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M20 12h-2.909M12 20v-2.909M4 12h2.909\"/><path d=\"M13.303 11.55a1.42 1.42 0 11-2.566 1.001l-.616-4.123a.466.466 0 01.841-.328l2.34 3.45z\" fill=\"currentColor\" fill-rule=\"nonzero\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});