define("ember-tracked-nested/index", ["exports", "ember-tracked-nested/-private/nested", "ember-tracked-nested/-private/decorator"], function (_exports, _nested, _decorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "nested", {
    enumerable: true,
    get: function () {
      return _nested.nested;
    }
  });
  Object.defineProperty(_exports, "trackedNested", {
    enumerable: true,
    get: function () {
      return _decorator.trackedNested;
    }
  });
});