define("ember-svg-jar/inlined/fastMoving", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>_army / icon / fastMoving</title><g stroke=\"currentColor\" stroke-width=\"1.059\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M0 13h14.444M8.435 7l6 6-6 6M18.778 13h14.444M27.213 7l6 6-6 6M37.556 13H52M45.99 7l6 6-6 6\"/></g>",
    "attrs": {
      "width": "52",
      "height": "24",
      "viewBox": "0 0 52 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});