define("ember-svg-jar/inlined/warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>_army / icon/warning</title><g fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke=\"currentColor\" stroke-width=\"1.5\"><path d=\"M13.109 2.664A1.264 1.264 0 0012 2c-.461 0-.886.254-1.109.664L2.658 18.093c-.217.398-.21.883.018 1.275.228.392.642.632 1.09.632h16.467c.449 0 .863-.24 1.091-.632.228-.392.235-.877.018-1.275L13.11 2.664zM12 16.143a.32.32 0 01.317.321M11.683 16.464a.32.32 0 01.317-.321M12 16.786a.32.32 0 01-.317-.322M12.317 16.464a.32.32 0 01-.317.322M12 13.571V7.786\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});