define("ember-svg-jar/inlined/recommendation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\"><path d=\"M15 17.25v2.25a1.5 1.5 0 01-1.5 1.5h-3A1.5 1.5 0 019 19.5v-2.25m3-16.5v1.5m-10.5 9H3m.75-7.5l1.1 1.057M22.5 11.25H21m-.75-7.5l-1.1 1.057M8.915 17.25h6.17a6.6 6.6 0 003.665-6A6.873 6.873 0 0012 4.5a6.873 6.873 0 00-6.75 6.754 6.6 6.6 0 003.665 5.996h0zM12 21v2.25m0-9.75v3.75\"/><path d=\"M9.75 11.25L12 13.5l2.25-2.25\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});