define("ember-svg-jar/inlined/pin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g stroke=\"currentColor\" stroke-width=\"1.5\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M8.25 8.25a3.75 3.75 0 107.5 0 3.75 3.75 0 00-7.5 0z\"/><path d=\"M12 .75a7.5 7.5 0 017.5 7.5c0 3.407-5.074 11.95-6.875 14.665a.75.75 0 01-1.25 0C9.574 20.2 4.5 11.657 4.5 8.25A7.5 7.5 0 0112 .75z\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});