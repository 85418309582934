define("ember-svg-jar/inlined/copy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>_army / icon/copy</title><g transform=\"translate(.75 .75)\" stroke=\"#07BFE4\" stroke-width=\"1.5\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M13.846 5.192v-1.73a1.73 1.73 0 00-1.73-1.731h-1.731M6.058 17.308H1.73A1.73 1.73 0 010 15.577V3.462C0 2.506.775 1.73 1.73 1.73h1.732\"/><rect x=\"10.385\" y=\"8.654\" width=\"12.115\" height=\"13.846\" rx=\".431\"/><path d=\"M10.817 0H3.03l.71 2.804c.089.386.434.66.83.658h4.708a.848.848 0 00.83-.658L10.818 0zM13.846 13.846h5.192M13.846 17.308h5.192\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});