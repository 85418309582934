define("ember-svg-jar/inlined/region", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"currentColor\" d=\"M0 0h18v18H0z\"/><rect stroke=\"#F2F2F2\" stroke-width=\"1.5\" x=\"1.75\" y=\"1.75\" width=\"14.5\" height=\"14.5\" rx=\"2\"/><path fill=\"#F2F2F2\" d=\"M8 8h6v6H8z\"/></g>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});