define("ember-svg-jar/inlined/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>_army / icon/user</title><g stroke=\"currentColor\" stroke-width=\"1.5\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M6.75 9.75a5.25 5.25 0 1010.5 0 5.25 5.25 0 00-10.5 0h0zM18.913 20.876a9.746 9.746 0 00-13.826 0\"/><path d=\"M.75 12c0 6.213 5.037 11.25 11.25 11.25S23.25 18.213 23.25 12 18.213.75 12 .75.75 5.787.75 12z\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});