define("ember-svg-jar/inlined/weather", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>icon/weather</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#06315D\" d=\"M0 0h24v24H0z\"/><g stroke=\"#FFF\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\"><path d=\"M7.5 78v-3M3 82.5H0M10.682 79.318l2.121-2.121M4.318 79.318l-2.121-2.121M3.75 84.984a4.5 4.5 0 117.647-4.734M15.75 89.25L12 93h4.5L12 97.5\"/><path d=\"M19.5 92.197a6 6 0 10-8.887-6.319A3.745 3.745 0 109 93\"/></g></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg",
      "style": "background:#06315d"
    }
  };
  _exports.default = _default;
});