define("ember-svg-jar/inlined/users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.714\" fill=\"none\" fill-rule=\"evenodd\"><path d=\"M8.714 10.571a4.286 4.286 0 100-8.571 4.286 4.286 0 000 8.571zM1 22.571h15.429v-.929A7.732 7.732 0 008.365 14 7.731 7.731 0 001 21.642v.93zM15.571 10.571a4.286 4.286 0 000-8.571M19.857 22.571h3.429v-.929a7.732 7.732 0 00-6-7.454\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});