define("ember-elsewhere/components/multiple-from-elsewhere", ["exports", "@ember/component", "@ember/template-factory", "ember-elsewhere/components/from-elsewhere"], function (_exports, _component, _templateFactory, _fromElsewhere) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.initialized}}
    {{#each (get this.service.actives this.name) as |source|}}
      {{#if (has-block)}}
        {{yield source.component source.outsideParams}}
      {{else}}
        {{component (ensure-safe-component source.component) }}
      {{/if}}
    {{/each}}
  {{/if}}
  
  */
  {
    "id": "bqF3Cavt",
    "block": "[[[41,[30,0,[\"initialized\"]],[[[42,[28,[37,2],[[28,[37,2],[[28,[37,3],[[30,0,[\"service\",\"actives\"]],[30,0,[\"name\"]]],null]],null]],null],null,[[[41,[48,[30,2]],[[[1,\"      \"],[18,2,[[30,1,[\"component\"]],[30,1,[\"outsideParams\"]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[46,[28,[37,7],[[30,1,[\"component\"]]],null],null,null,null],[1,\"\\n\"]],[]]]],[1]],null]],[]],null]],[\"source\",\"&default\"],false,[\"if\",\"each\",\"-track-array\",\"get\",\"has-block\",\"yield\",\"component\",\"ensure-safe-component\"]]",
    "moduleName": "ember-elsewhere/components/multiple-from-elsewhere.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, class extends _fromElsewhere.default {});

  _exports.default = _default;
});