define("ember-svg-jar/inlined/check-separate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M10.778.167a.792.792 0 01.139 1.11L4.762 9.195a.792.792 0 01-1.17.09L.247 6.116a.792.792 0 111.089-1.15l2.711 2.567L9.667.306a.792.792 0 011.11-.14z\" fill=\"#333\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "11.083",
      "height": "9.5",
      "viewBox": "0 0 11.083 9.5",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});