define("ember-svg-jar/inlined/camera", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>_army / icon/camera</title><g stroke=\"#000\" stroke-width=\"1.5\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M7 1.875a6 6 0 100 12h9a3 3 0 003-3v-2.25l3.671-1.835a1.5 1.5 0 00.829-1.342V2.625a.75.75 0 00-.75-.75H7z\"/><path d=\"M22 7.125v3.75a1.5 1.5 0 01-1.5 1.5h-1.9M8.5 13.875v3.333a2.848 2.848 0 01-3 2.667H1M1 17.625v4.5M8.5 7.875c.207 0 .375.168.375.375M8.125 8.25c0-.207.168-.375.375-.375M8.5 8.625a.375.375 0 01-.375-.375M8.875 8.25a.375.375 0 01-.375.375M1 7.875h3.75\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});