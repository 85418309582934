define("ember-svg-jar/inlined/groups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.714\" fill=\"none\" fill-rule=\"evenodd\"><circle cx=\"17.971\" cy=\"4.086\" r=\"3.086\"/><path d=\"M22.6 14.886v-1.543a4.629 4.629 0 00-8.68-2.241\"/><circle cx=\"5.629\" cy=\"4.086\" r=\"3.086\"/><path d=\"M1 14.886v-1.543a4.629 4.629 0 018.68-2.241\"/><circle cx=\"11.802\" cy=\"13.339\" r=\"3.086\"/><path d=\"M16.43 22.596a4.629 4.629 0 10-9.257 0\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});