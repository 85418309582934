define("ember-svg-jar/inlined/rain", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>_army / icon / weather / rain</title><g stroke=\"currentColor\" stroke-width=\"1.5\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M15.501 19.501l-2.25 2.25M6.501 19.501l-2.25 2.25M11.751 14.25l-3 3h4.5L9.501 21\"/><path d=\"M14.751 14.25A6 6 0 108.864 7.13a3.745 3.745 0 10-1.613 7.122h7.5z\"/><path d=\"M8.751 8.25a5.993 5.993 0 000 0c.002-.376.04-.752.113-1.121\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});