define("ember-svg-jar/inlined/floor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"currentColor\" d=\"M0 0h18v18H0z\"/><path stroke=\"#FFF\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M1.465 9.181l7.562 3.49 7.562-3.49\"/><path stroke=\"#FFF\" stroke-width=\"1.5\" opacity=\".5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M1.465 5.946l7.562 3.49 7.562-3.49-7.562-3.817zM1.465 12.639l7.562 3.49 7.562-3.49\"/></g>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});