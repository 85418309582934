define("ember-svg-jar/inlined/info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g stroke=\"currentColor\" stroke-width=\"1.5\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M14.25 16.5h-.75A1.5 1.5 0 0112 15v-3.75a.75.75 0 00-.75-.75h-.75M11.625 6.75a.375.375 0 100 .75.375.375 0 000-.75h0\"/><path d=\"M.75 12c0 6.213 5.037 11.25 11.25 11.25S23.25 18.213 23.25 12 18.213.75 12 .75.75 5.787.75 12z\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});