define("ember-svg-jar/inlined/sun", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>_army / icon / weather / sun</title><g stroke=\"currentColor\" stroke-width=\"1.5\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M5.25 12a6.75 6.75 0 1013.5 0 6.75 6.75 0 00-13.5 0zM12 2.25V.75M18.894 5.106l1.061-1.061M21.75 12h1.5M18.894 18.894l1.061 1.061M12 21.75v1.5M5.106 18.894l-1.061 1.061M2.25 12H.75M5.106 5.106L4.045 4.045\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});