define("ember-svg-jar/inlined/thunder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>_army / icon / weather / thunder</title><g stroke=\"currentColor\" stroke-width=\"1.5\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M8.25 3.75v-3M3.75 8.25h-3M11.432 5.068l2.121-2.121M5.068 5.068L2.947 2.947M4.5 10.734A4.5 4.5 0 1112.147 6M16.5 15l-3.75 3.75h4.5l-4.5 4.5\"/><path d=\"M20.25 17.947a6 6 0 10-8.887-6.319A3.745 3.745 0 109.75 18.75\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});