define("ember-svg-jar/inlined/layers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>_army / icon/layers</title><g stroke=\"#000\" stroke-width=\"1.5\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M22.908 6.953l-10.21-5.281a1.543 1.543 0 00-1.416 0L1.074 6.953a.615.615 0 000 1.094l10.209 5.281c.444.23.972.23 1.416 0l10.209-5.281a.616.616 0 000-1.094z\"/><path d=\"M.756 12.75l10.527 5.078c.444.23.972.23 1.416 0l10.557-5.078\"/><path d=\"M.756 17.25l10.527 5.078c.444.23.972.23 1.416 0l10.557-5.078\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});