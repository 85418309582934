define("ember-resize-modifier/modifiers/did-resize", ["exports", "ember-modifier", "@ember/destroyable"], function (_exports, _emberModifier, _destroyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class DidResizeModifier extends _emberModifier.default {
    // Public API
    // Private API
    constructor() {
      super(...arguments);

      _defineProperty(this, "element", void 0);

      _defineProperty(this, "handler", void 0);

      _defineProperty(this, "options", {});

      if (!('ResizeObserver' in window)) {
        return;
      }

      if (!DidResizeModifier.observer) {
        DidResizeModifier.handlers = new WeakMap();
        DidResizeModifier.observer = new ResizeObserver((entries, observer) => {
          window.requestAnimationFrame(() => {
            for (let entry of entries) {
              const handler = DidResizeModifier.handlers.get(entry.target);
              if (handler) handler(entry, observer);
            }
          });
        });
      }

      (0, _destroyable.registerDestructor)(this, unobserve);
    }

    modify(element, positional
    /*, named*/
    ) {
      unobserve(this);
      this.element = element;
      const [handler, options] = positional; // Save arguments for when we need them

      this.handler = handler;
      this.options = options || this.options;
      this.observe();
    }

    observe() {
      if (DidResizeModifier.observer) {
        this.addHandler();
        DidResizeModifier.observer.observe(this.element, this.options);
      }
    }

    addHandler() {
      DidResizeModifier.handlers.set(this.element, this.handler);
    }

    removeHandler() {
      DidResizeModifier.handlers.delete(this.element);
    }

  }
  /**
   *
   * @param {DidResizeModifier} instance
   */


  _exports.default = DidResizeModifier;

  _defineProperty(DidResizeModifier, "observer", null);

  _defineProperty(DidResizeModifier, "handlers", null);

  function unobserve(instance) {
    if (instance.element && DidResizeModifier.observer) {
      DidResizeModifier.observer.unobserve(instance.element);
      instance.removeHandler();
    }
  }
});