define("ember-svg-jar/inlined/delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M2.5 4.863h13M10.33 2.5H7.67a.886.886 0 00-.886.886v1.478h4.431V3.386a.886.886 0 00-.886-.886zM7.67 12.398V7.966M10.33 12.398V7.966\" stroke=\"currentColor\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M13.054 14.684a.88.88 0 01-.88.816H5.826a.88.88 0 01-.88-.816l-.822-9.82h9.75l-.821 9.82z\" stroke=\"currentColor\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});