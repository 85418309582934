define("ember-aria-tabs/components/aria-tab-list", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ul class={{this.className}} role="tablist" ...attributes>
    {{yield
      (hash
        tab=(component
          "aria-tab"
          didInsertNode=@didInsertNode
          disabledTabClassName=@disabledTabClassName
          focus=@focus
          onClick=@onClick
          onKeyUp=@onKeyUp
          panelIds=@panelIds
          selectedTabClassName=@selectedTabClassName
          selectedIndex=@selectedIndex
          tabIds=@tabIds
          willDestroyNode=@willDestroyNode
        )
      )
    }}
  </ul>
  */
  {
    "id": "3PVXl3U4",
    "block": "[[[11,\"ul\"],[16,0,[30,0,[\"className\"]]],[24,\"role\",\"tablist\"],[17,1],[12],[1,\"\\n  \"],[18,12,[[28,[37,1],null,[[\"tab\"],[[50,\"aria-tab\",0,null,[[\"didInsertNode\",\"disabledTabClassName\",\"focus\",\"onClick\",\"onKeyUp\",\"panelIds\",\"selectedTabClassName\",\"selectedIndex\",\"tabIds\",\"willDestroyNode\"],[[30,2],[30,3],[30,4],[30,5],[30,6],[30,7],[30,8],[30,9],[30,10],[30,11]]]]]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"@didInsertNode\",\"@disabledTabClassName\",\"@focus\",\"@onClick\",\"@onKeyUp\",\"@panelIds\",\"@selectedTabClassName\",\"@selectedIndex\",\"@tabIds\",\"@willDestroyNode\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-aria-tabs/components/aria-tab-list.hbs",
    "isStrictMode": false
  });

  const DEFAULT_CLASS = 'ember-tabs__tab-list';
  /**
   * List of all tabs component rendered as `<ul />` .
   *
   * If you specify additional props on the `<AriaTabList />` component they will be forwarded to the rendered `<ul />`.
   *
   * Default CSS class: `ember-tabs__tab-list`
   *
   * @class AriaTabList
   * @public
   */

  class AriaTabListComponent extends _component2.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "className", DEFAULT_CLASS);
    }

  }

  _exports.default = AriaTabListComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AriaTabListComponent);
});