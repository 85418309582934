define("ember-svg-jar/inlined/system", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M0 0h18v18H0z\"/><path d=\"M5.25 12.5c-.449 0-.813.313-.813.7 0 .387.364.7.813.7.449 0 .813-.313.813-.7 0-.387-.364-.7-.813-.7zm0-4.2c-.449 0-.813.313-.813.7 0 .387.364.7.813.7.449 0 .813-.313.813-.7 0-.387-.364-.7-.813-.7zm0-4.2c-.449 0-.813.313-.813.7 0 .387.364.7.813.7.449 0 .813-.313.813-.7 0-.387-.364-.7-.813-.7zm9.75 0C15 2.94 13.909 2 12.562 2H4.438C3.09 2 2 2.94 2 4.1v1.4c.004.518.23 1.017.634 1.4-.405.383-.63.882-.634 1.4v1.4c.004.518.23 1.017.634 1.4-.405.383-.63.882-.634 1.4v1.4C2 15.06 3.091 16 4.438 16h8.125C13.909 16 15 15.06 15 13.9v-1.4c-.004-.518-.23-1.017-.634-1.4.405-.383.63-.882.634-1.4V8.3c-.004-.518-.23-1.017-.634-1.4.405-.383.63-.882.634-1.4V4.1zm-1.625 9.8c0 .387-.364.7-.813.7H4.438c-.45 0-.813-.313-.813-.7v-1.4c0-.387.364-.7.813-.7h8.125c.448 0 .812.313.812.7v1.4zm0-4.2c0 .387-.364.7-.813.7H4.438c-.45 0-.813-.313-.813-.7V8.3c0-.387.364-.7.813-.7h8.125c.448 0 .812.313.812.7v1.4zm0-4.2c0 .387-.364.7-.813.7H4.438c-.45 0-.813-.313-.813-.7V4.1c0-.387.364-.7.813-.7h8.125c.448 0 .812.313.812.7v1.4z\" fill=\"currentColor\" fill-rule=\"nonzero\"/></g>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});