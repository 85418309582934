define("ember-svg-jar/inlined/car", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>icon/car</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#06315D\" d=\"M0 0h24v24H0z\"/><g stroke=\"#FFF\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\"><path d=\"M20.25 80.25l-1.7-3.79A2.249 2.249 0 0016.441 75H6.059a2.249 2.249 0 00-2.109 1.46l-1.7 3.79M.75 86.968V88.5a1.5 1.5 0 103 0V87M21.75 86.968V88.5a1.5 1.5 0 11-3 0V87M19.125 83.25c.207 0 .375.168.375.375M18.75 83.625c0-.207.168-.375.375-.375M19.125 84a.375.375 0 01-.375-.375M19.5 83.625a.375.375 0 01-.375.375M3.375 83.25a.375.375 0 00-.375.375M3.75 83.625a.375.375 0 00-.375-.375M3.375 84a.375.375 0 00.375-.375M3 83.625c0 .207.168.375.375.375\"/><path d=\"M2 80.25h18.5a2 2 0 012 2V86a1 1 0 01-1 1H1a1 1 0 01-1-1v-3.75a2 2 0 012-2z\"/></g></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg",
      "style": "background:#06315d"
    }
  };
  _exports.default = _default;
});