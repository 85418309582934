define("ember-svg-jar/inlined/expand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g stroke=\"currentColor\" stroke-width=\"1.125\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M23.5 7.75V1h-6.75M23.5 1l-15 15M11.5 5.5h-9A1.5 1.5 0 001 7v15a1.5 1.5 0 001.5 1.5h15A1.5 1.5 0 0019 22v-9\"/></g>",
    "attrs": {
      "width": "25",
      "height": "25",
      "viewBox": "0 0 25 25",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});