define("ember-svg-jar/inlined/alert 2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>icon/alert 2</title><g fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke=\"#F2F4F6\" stroke-width=\"1.5\"><ellipse cx=\"17.57\" cy=\"7.062\" rx=\"2.43\" ry=\"2.4\"/><path d=\"M9.67 19.8c.212.712.873 1.2 1.623 1.2s1.411-.488 1.622-1.2M11.291 4.8V3M17.386 11.97c.153 4.604 1.196 5.43 1.196 5.43H4s1.215-1.532 1.215-6.6A5.982 5.982 0 017.69 5.971a6.144 6.144 0 015.404-.903\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});