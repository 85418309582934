define("ember-svg-jar/inlined/download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M17.25 10.5a6.75 6.75 0 110 13.5 6.75 6.75 0 010-13.5zm0 1.5a5.25 5.25 0 100 10.5 5.25 5.25 0 000-10.5zm0 1.5c.38 0 .7.28.74.65l.01.1v4.19l.97-.97a.75.75 0 011.13.98l-.07.08-2.25 2.25a.75.75 0 01-.08.07l-.01.01a.75.75 0 01-.09.05h-.01a.74.74 0 01-.11.05.7.7 0 01-.23.04h-.11a.75.75 0 01-.09-.03h-.02a.75.75 0 01-.07-.03l-.03-.01a.74.74 0 01-.21-.15l-2.25-2.25a.75.75 0 01.98-1.13l.08.07.97.97v-4.19c0-.41.34-.75.75-.75zM12.88 0c.53 0 1.04.19 1.44.53l.15.13 2.87 2.87c.38.38.6.87.65 1.4l.01.2v3.12a.75.75 0 01-1.5.1V5.12a.75.75 0 00-.14-.44l-.08-.09-2.87-2.87A.75.75 0 0013 1.5l-.12-.01H2.25c-.38 0-.7.28-.74.65l-.01.1v16.5c0 .38.28.7.65.74l.1.01h6a.75.75 0 01.1 1.5h-6.1c-1.2 0-2.17-.93-2.24-2.1L0 18.75V2.25C0 1.05.93.08 2.1.01L2.25 0h10.63z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});