define("ember-svg-jar/inlined/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path clip-rule=\"evenodd\" d=\"M6.429 13.864L3 14.48l.571-3.474 7.692-7.669a1.143 1.143 0 011.623 0l1.211 1.211a1.143 1.143 0 010 1.623L6.43 13.864z\" stroke=\"currentColor\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path stroke=\"currentColor\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M9.632 5l2.645 2.645\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});