define("ember-svg-jar/inlined/filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>icon/filter</title><path d=\"M18 10.83a3.001 3.001 0 010-5.66V4a1 1 0 012 0v1.17a3.001 3.001 0 010 5.66V20a1 1 0 01-2 0v-9.17zM9 16c0-1.306.835-2.417 2-2.83V4a1 1 0 012 0v9.17a3.001 3.001 0 010 5.66V20a1 1 0 01-2 0v-1.17A3.001 3.001 0 019 16zm-7-4c0-1.306.835-2.417 2-2.83V4a1 1 0 112 0v5.17a3.001 3.001 0 010 5.66V20a1 1 0 01-2 0v-5.17A3.001 3.001 0 012 12zm3 1a1 1 0 100-2 1 1 0 000 2zm7 4a1 1 0 100-2 1 1 0 000 2zm7-8a1 1 0 100-2 1 1 0 000 2z\" fill=\"currentColor\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});