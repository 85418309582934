define("ember-svg-jar/inlined/target", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>_army / icon/target</title><g stroke=\"#000\" stroke-width=\"1.5\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M15.75 12.002a3.75 3.75 0 107.5 0 3.75 3.75 0 00-7.5 0z\"/><path d=\"M19.5 11.627a.375.375 0 110 .75.375.375 0 010-.75M10.5 9.752l2.25 2.25-2.25 2.25M5.25 12.002h7.5M19.293 5.25a10.5 10.5 0 100 13.5\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});