define("ember-svg-jar/inlined/building", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M0 0h18v18H0z\"/><path d=\"M10.905 4.2h4.952c.631 0 1.143.537 1.143 1.2v10.4c0 .663-.512 1.2-1.143 1.2H2.143C1.512 17 1 16.463 1 15.8V2.2C1 1.537 1.512 1 2.143 1h7.619c.631 0 1.143.537 1.143 1.2v2zM9.38 15.4V2.6H2.524v12.8H9.38zm1.524-9.6v9.6h4.571V5.8h-4.571zM4.429 4.2h.761c.21 0 .381.18.381.4v1.6c0 .22-.17.4-.38.4h-.762a.39.39 0 01-.381-.4V4.6c0-.22.17-.4.38-.4zm2.285 0h.762c.21 0 .381.18.381.4v1.6c0 .22-.17.4-.38.4h-.763a.39.39 0 01-.38-.4V4.6c0-.22.17-.4.38-.4zM4.43 7.4h.761c.21 0 .381.18.381.4v1.6c0 .22-.17.4-.38.4h-.762a.39.39 0 01-.381-.4V7.8c0-.22.17-.4.38-.4zm0 3.2h.761c.21 0 .381.18.381.4v1.6c0 .22-.17.4-.38.4h-.762a.39.39 0 01-.381-.4V11c0-.22.17-.4.38-.4zm2.285-3.2h.762c.21 0 .381.18.381.4v1.6c0 .22-.17.4-.38.4h-.763a.39.39 0 01-.38-.4V7.8c0-.22.17-.4.38-.4zm0 3.2h.762c.21 0 .381.18.381.4v1.6c0 .22-.17.4-.38.4h-.763a.39.39 0 01-.38-.4V11c0-.22.17-.4.38-.4zm6.096 0h.761c.21 0 .381.18.381.4v1.6c0 .22-.17.4-.38.4h-.762a.39.39 0 01-.381-.4V11c0-.22.17-.4.38-.4zm0-3.2h.761c.21 0 .381.18.381.4v1.6c0 .22-.17.4-.38.4h-.762a.39.39 0 01-.381-.4V7.8c0-.22.17-.4.38-.4z\" fill=\"currentColor\" fill-rule=\"nonzero\"/></g>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});