define("ember-svg-jar/inlined/search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"currentColor\" d=\"M-727-391H713V909H-727z\"/><path fill=\"#F7F7F7\" d=\"M-228-10H34v44h-262z\"/><g stroke=\"#000\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\"><path d=\"M4.5 10.542a6.042 6.042 0 1012.084 0 6.042 6.042 0 00-12.084 0zM14.814 14.814l4.686 4.687\"/></g></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});