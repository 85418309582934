define("ember-svg-jar/inlined/alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>icon/alert</title><g transform=\"translate(1 1)\" stroke=\"currentColor\" stroke-width=\"1.5\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><ellipse cx=\"13.57\" cy=\"4.062\" rx=\"2.43\" ry=\"2.4\"/><path d=\"M5.67 16.8c.212.712.873 1.2 1.623 1.2s1.411-.488 1.622-1.2M7.291 1.8V0M13.386 8.97c.153 4.604 1.196 5.43 1.196 5.43H0s1.215-1.532 1.215-6.6A5.982 5.982 0 013.69 2.971a6.144 6.144 0 015.404-.903\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});