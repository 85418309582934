define("ember-svg-jar/inlined/alerts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>icon/alerts</title><g transform=\"translate(1 2)\" stroke=\"#000\" stroke-width=\"1.714\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><circle cx=\"18\" cy=\"4.286\" r=\"4.286\"/><path d=\"M20.571 12.857v6c0 .947-.767 1.714-1.714 1.714H1.714A1.714 1.714 0 010 18.857v-12c0-.947.768-1.714 1.714-1.714h6.857\"/><path d=\"M0 7.303l10.286 7.268 3.548-2.502\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});