define("ember-svg-jar/inlined/room", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"currentColor\" d=\"M0 0h18v18H0z\"/><path d=\"M10.765 15.428v.388c0 .654-.58 1.184-1.294 1.184-.126 0-.252-.017-.372-.05l-5.177-1.42C3.375 15.38 3 14.919 3 14.396V4.184C3 3.53 3.58 3 4.294 3h8.412C13.42 3 14 3.53 14 4.184v10.06c0 .654-.58 1.184-1.294 1.184h-1.941zM8.149 4.184l1.708.489c.54.154.908.612.908 1.13v8.442h1.94V4.184H8.15zm-3.855.137v10.075l5.177 1.42V5.802L4.294 4.321zm3.559 6.964c-.536 0-.97-.397-.97-.887s.434-.888.97-.888.97.397.97.888c0 .49-.434.887-.97.887z\" fill=\"#FFF\" fill-rule=\"nonzero\"/></g>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});