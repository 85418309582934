define("ember-svg-jar/inlined/acp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>_army / icon/acp</title><path d=\"M13.16.42l.123.111L23.47 10.72c.669.668.706 1.73.111 2.442l-.111.122L13.283 23.47a1.813 1.813 0 01-2.442.111l-.122-.111L.53 13.283A1.813 1.813 0 01.42 10.84l.111-.122L10.72.53A1.813 1.813 0 0113.16.42zm-1.324 1.127l-.057.045L1.592 11.779a.313.313 0 00-.045.386l.045.057 10.187 10.187c.105.105.266.12.386.045l.057-.045 10.187-10.187a.313.313 0 00.045-.386l-.045-.057L12.222 1.592a.313.313 0 00-.386-.045zM9.75 6.001c.039 0 .076.002.113.008l.042.008a.717.717 0 01.206.076l.035.02.033.022a.573.573 0 01.018.013l.012.009.072.063 2.25 2.25a.75.75 0 01-.977 1.134l-.084-.073-.97-.97v1.892a.75.75 0 00.25.558l.084.065 3.164 2.106a2.25 2.25 0 01.996 1.699l.006.173V18a.75.75 0 01-1.493.101l-.007-.101v-2.947a.75.75 0 00-.25-.559l-.083-.065-3.165-2.105a2.25 2.25 0 01-.995-1.7L9 10.453V8.559l-.97.972a.75.75 0 01-.976.073L6.97 9.53a.75.75 0 01-.073-.977l.073-.084 2.25-2.25.08-.069.03-.021a.764.764 0 01.316-.122l.051-.006.053-.001zm0 8.25a.75.75 0 01.743.648L10.5 15v3a.75.75 0 01-1.493.101L9 18.001v-3a.75.75 0 01.75-.75zm4.5-7.5a.75.75 0 01.743.648L15 7.5v3a.75.75 0 01-1.493.101l-.007-.101v-3a.75.75 0 01.75-.75z\" fill=\"#333\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});