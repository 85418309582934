define("ember-svg-jar/inlined/energy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>icon/energy</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#06315D\" d=\"M0 0h24v24H0z\"/><path d=\"M13.581.389l-1.608 9.048h6.53L5.815 23.867l5.28-10.523H6.757L13.581.389z\" stroke=\"#FFF\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg",
      "style": "background:#06315d"
    }
  };
  _exports.default = _default;
});