define("ember-validators/inclusion", ["exports", "@ember/debug", "@ember/utils", "ember-validators/utils/validation-error"], function (_exports, _debug, _utils, _validationError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateInclusion;

  /**
   *  @class Inclusion
   *  @module Validators
   */

  /**
   * @method validate
   * @param {Any} value
   * @param {Object} options
   * @param {Boolean} options.allowBlank If true, skips validation if the value is empty
   * @param {Array} options.in The list of values this attribute could be
   * @param {Array} options.range The range in which the attribute's value should reside in
   * @param {Object} model
   * @param {String} attribute
   */
  function validateInclusion(value, options, model, attribute) {
    let array = options.in;
    let {
      range,
      allowBlank
    } = options;
    (false && !(!(0, _utils.isEmpty)(Object.keys(options))) && (0, _debug.assert)(`[validator:inclusion] [${attribute}] no options were passed in`, !(0, _utils.isEmpty)(Object.keys(options))));

    if (allowBlank && (0, _utils.isEmpty)(value)) {
      return true;
    }

    if (array && array.indexOf(value) === -1) {
      return (0, _validationError.default)('inclusion', value, options);
    }

    if (range && range.length === 2) {
      let [min, max] = range;
      let equalType = (0, _utils.typeOf)(value) === (0, _utils.typeOf)(min) && (0, _utils.typeOf)(value) === (0, _utils.typeOf)(max);
      let isInvalidNumber = (0, _utils.typeOf)(value) === 'number' && isNaN(value);

      if (!equalType || isInvalidNumber || min > value || value > max) {
        return (0, _validationError.default)('inclusion', value, options);
      }
    }

    return true;
  }
});