define("ember-svg-jar/inlined/map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>_army / icon/map</title><g stroke=\"currentColor\" stroke-width=\"1.5\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M23.25 9V4.65a1.5 1.5 0 00-.943-1.393l-6-2.4a1.5 1.5 0 00-1.114 0L8.807 3.412a1.5 1.5 0 01-1.114 0L1.779 1.046a.75.75 0 00-1.029.7V16.12a1.5 1.5 0 00.943 1.393l6 2.4a1.5 1.5 0 001.114 0l2.881-1.153M8.25 3.519v16.5M15.75.75v7.5M18.75 15.45c.207 0 .375.167.375.374M18.375 15.824c0-.207.168-.375.375-.375M18.75 16.2a.375.375 0 01-.375-.375M19.125 15.824a.375.375 0 01-.375.375\"/><path d=\"M18.75 11.324a4.5 4.5 0 014.5 4.5c0 1.921-2.688 5.576-3.909 7.138a.75.75 0 01-1.182 0c-1.221-1.56-3.909-5.217-3.909-7.138a4.5 4.5 0 014.5-4.5h0z\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});