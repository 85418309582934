define("ember-elsewhere/components/from-elsewhere", ["exports", "@ember/component", "@ember/template-factory", "@ember/application", "@ember/runloop", "rsvp", "@ember/service", "@glimmer/component", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _application, _runloop, _rsvp, _service, _component2, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.initialized}}
    {{#let (get this.service.actives this.name) as |active| }}
      {{#if (has-block)}}
        {{yield active.lastObject.component active.lastObject.outsideParams}}
      {{else}}
        {{#let active.lastObject.component as |c|}}
          {{component (ensure-safe-component c) }}
        {{/let}}
      {{/if}}
    {{/let}}
  {{/if}}
  
  */
  {
    "id": "J0PVbTCQ",
    "block": "[[[41,[30,0,[\"initialized\"]],[[[44,[[28,[37,2],[[30,0,[\"service\",\"actives\"]],[30,0,[\"name\"]]],null]],[[[41,[48,[30,3]],[[[1,\"      \"],[18,3,[[30,1,[\"lastObject\",\"component\"]],[30,1,[\"lastObject\",\"outsideParams\"]]]],[1,\"\\n\"]],[]],[[[44,[[30,1,[\"lastObject\",\"component\"]]],[[[1,\"        \"],[46,[28,[37,6],[[30,2]],null],null,null,null],[1,\"\\n\"]],[2]]]],[]]]],[1]]]],[]],null]],[\"active\",\"c\",\"&default\"],false,[\"if\",\"let\",\"get\",\"has-block\",\"yield\",\"component\",\"ensure-safe-component\"]]",
    "moduleName": "ember-elsewhere/components/from-elsewhere.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (_dec = (0, _service.inject)('ember-elsewhere'), (_class = class _class extends _component2.default {
    get name() {
      if (this.args.named) {
        throw new Error(`<FromElsewhere> takes a "@name=" parameter, not "@named="`);
      }

      return this.args.name ?? 'default';
    } // We don't yield any content on the very first render pass, because
    // we want to give any concurrent {{to-elsewhere}} components a chance
    // to declare their intentions first. This allows the components
    // inside us to see a meaningful initial value on their initial
    // render.


    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "service", _descriptor, this);

      _initializerDefineProperty(this, "initialized", _descriptor2, this);

      let promise = new _rsvp.Promise(resolve => {
        (0, _runloop.schedule)('afterRender', () => {
          this.initialized = true;
          resolve();
        });
      });
      let fastboot = (0, _application.getOwner)(this).lookup('service:fastboot');

      if (fastboot && fastboot.isFastBoot) {
        fastboot.deferRendering(promise);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "service", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "initialized", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class)));

  _exports.default = _default;
});