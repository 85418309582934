define("ember-svg-jar/inlined/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M5 6h3v2H5V6zM10 6h9v2h-9V6zM5 11h3v2H5v-2zM10 11h7v2h-7v-2zM5 16h3v2H5v-2zM10 16h9v2h-9v-2z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});