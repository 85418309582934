define("ember-svg-jar/inlined/data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>icon/data</title><g stroke=\"#F2F4F6\" stroke-width=\"1.5\" fill=\"none\" fill-rule=\"evenodd\" stroke-linejoin=\"round\"><path d=\"M19 5H4v15h15z\"/><path d=\"M4 13.182h2.727l1.364-2.727 2.727 6.136 2.727-8.864 1.364 5.455H19\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});